<template>
  <div class="editor" id="topId">
    <div class="editor-box">
      <h2>发布文章</h2>
      <div class="p">
        <p>
          禁止发布违反国家法律法规、行业规范的内容、涉及未成年人保护的内容、违反社会公序良俗、价值观负面消极内容、低俗内容、恶意等内容
        </p>
      </div>

      <el-form
        ref="form"
        :label-position="LabelPosition"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="标题">
          <el-input
            placeholder="请选择文章标题"
            v-model="form.title"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="即时配送">
          <el-switch v-model="form.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="活动性质">
          <el-checkbox-group v-model="form.type">
            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
            <el-checkbox label="地推活动" name="type"></el-checkbox>
            <el-checkbox label="线下主题活动" name="type"></el-checkbox>
            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
        <!-- <el-form-item label="软">
          <el-radio-group v-model="form.resource">
            <el-radio label="线上品牌商赞助"></el-radio>
            <el-radio label="线下场地免费"></el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="内容">
          <!-- <el-input type="textarea" v-model="form.desc"></el-input> -->
          <v-editoe ref="editor"></v-editoe>
        </el-form-item>
        <el-form-item label="软件分类">
          <el-select v-model="form.region" placeholder="请选择文章相关">
            <el-option label="unity 3DplugIn" value="unity"></el-option>
            <el-option label="3D相框" value="3D相框"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="close">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import VEditoe from "./quill.vue";
export default {
  components: {
    VEditoe,
  },
  data() {
    return {
      form: {
        title: "",
        region: "",

        // delivery: false,
        // type: [],
        // resource: "",
        // desc: "",
      },
      LabelPosition: "right",
      screenWidth: document.body.clientWidth,
    };
  },
  methods: {
    onSubmit() {
      this.form.desc = this.$refs.editor.content;
      // console.log(this.$refs.editor.content);
      this.$message.success("发布成功");
      console.log(this.form);
    },
    close(){
       this.$confirm('此操作将不会保存当前所写内容, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '取消发布!'
          });
          this.$router.go(-1);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消操作'
          });          
        });
    }
  },
  watch: {
    screenWidth: {
      //监听首次屏幕大小
      immediate: true,
      handler(newValue) {
        if (newValue < 480) {
          this.LabelPosition = "top";
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.editor {
  background: #eee;
  text-align: left;
  .editor-box {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    background: white;
    h2 {
      padding-top: 20px;
    }
    .p {
      padding: 5px 0;
      font-size: 12px;
      color: #ee7070;
    }
  }
}
</style>