<template>
  <div>
    <quilleditor
      v-model="content"
      ref="myTextEditor"
      :options="editorOption"
      @change="onChange"
    >
      <!-- <div id="toolbar" slot="toolbar">
        <select class="ql-size">
          <option value="small"></option>
        
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
   
         <span class="ql-formats"
          ><button class="ql-align"></button
        ></span>
         <span class="ql-formats"
          ><button class="ql-font"></button
        ></span>

        <span class="ql-formats"
          ><button class="ql-script" value="sub"></button
        ></span>
        <span class="ql-formats"
          ><button class="ql-script" value="super"></button
        ></span>
        <span class="ql-formats"
          ><button type="button" class="ql-bold"></button
        ></span>
        <span class="ql-formats"
          ><button type="button" class="ql-italic"></button
        ></span>
        <span class="ql-formats"
          ><button type="button" class="ql-blockquote"></button
        ></span> 
        <span class="ql-formats"
          ><button type="button" class="ql-list" value="ordered"></button
        ></span>
        <span class="ql-formats"
          ><button type="button" class="ql-list" value="bullet"></button
        ></span>
        <span class="ql-formats"
          ><button type="button" class="ql-link"></button
        ></span>
        
        <span class="ql-formats">
          <button type="button" @click="imgClick" style="outline: none">
            <svg viewBox="0 0 18 18">
              <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect>
              <circle class="ql-fill" cx="6" cy="7" r="1"></circle>
              <polyline
                class="ql-even ql-fill"
                points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
              ></polyline>
            </svg>
          </button>
        </span>
        <span class="ql-formats"
          ><button type="button" class="ql-video"></button
        ></span>
      </div> -->
    </quilleditor>
  </div>
</template>
<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  name: "v-editor",
  props: {
    value: {
      type: String,
    },
    /*上传图片的地址*/
    uploadUrl: {
      type: String,
      default: "/",
    },
    /*上传图片的file控件name*/
    fileName: {
      type: String,
      default: "file",
    },
    maxUploadSize: {
      type: Number,
      default: 1024 * 1024 * 500,
    },
  },
  data() {
    return {
      content: "",
      editorOption: {
        placeholder: "输入点东西吧",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["clean"], //清除字体样式
            ["insertImg"],
          ],
        },
      },
    };
  },
  methods: {
    onChange() {
      this.$emit("input", this.content);
    },
    initButton() {
      const sourceEditorButton = document.querySelector(".ql-insertImg");
      sourceEditorButton.innerHTML = `<svg viewBox="0 0 18 18">
              <rect class="ql-stroke" height="10" width="12" x="3" y="4"></rect>
              <circle class="ql-fill" cx="6" cy="7" r="1"></circle>
              <polyline
                class="ql-even ql-fill"
                points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
              ></polyline>
            </svg>`;
      sourceEditorButton.onclick = this.imgClick;
    },

    /*选择上传图片切换*/
    onFileChange(e) {
      var fileInput = e.target;
      if (fileInput.files.length === 0) {
        return;
      }
      this.editor.focus();
      if (fileInput.files[0].size > this.maxUploadSize) {
        this.$alert("图片不能大于500KB", "图片尺寸过大", {
          confirmButtonText: "确定",
          type: "warning",
        });
      }
      var data = new FormData();
      data.append(this.fileName, fileInput.files[0]);
      this.$http.post(this.uploadUrl, data).then((res) => {
        if (res.data) {
          console.log(res.data);
          this.editor.insertEmbed(
            this.editor.getSelection().index,
            "image",
            res.data
          );
        }
      });
    },
    /*点击上传图片按钮*/
    imgClick() {
      if (!this.uploadUrl) {
        console.log("no editor uploadUrl");
        return;
      }
      /*内存创建input file*/
      var input = document.createElement("input");
      input.type = "file";
      input.name = this.fileName;
      input.accept = "image/jpeg,image/png,image/jpg,image/gif";
      input.onchange = this.onFileChange;
      input.click();
    },
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill;
    },
  },
  components: {
    quilleditor: quillEditor,
  },
  mounted() {
    this.content = this.value;
    this.initButton();
  },
  watch: {
    value(newVal, oldVal) {
      if (this.editor) {
        if (newVal !== this.content) {
          this.content = newVal;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ql-container{
  height: 300px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}
.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}
.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}
.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
